import { Header } from "./components/header/Header";
import { Nav } from "./components/nav/Nav";
import { About } from "./components/aboutComponent/About";
import { Experience } from "./components/experience/Experience";
import { Portfolio } from "./components/portfolio/Portfolio";
import { Contact } from "./components/contactComponent/Contact";
import { Footer } from "./components/footer/Footer";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Header />
      <Nav />
      <About />
      <Experience />
      <Portfolio />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
