import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { GrTwitter } from "react-icons/gr";
import "./Footer.css";

export const Footer = () => {
  return (
    <footer>
      <a href="home" className="footer__logo">
        Taku Feso
      </a>
      <ul className="permalinks">
        <li>
          <a href="home">Home</a>
        </li>
        <li>
          <a href="about">About</a>
        </li>
        <li>
          <a href="experience">Experience</a>
        </li>
        <li>
          <a href="portfolio">Portfolio</a>
        </li>
        <li>
          <a href="contact">Contact</a>
        </li>
      </ul>
      <div className="footer_socials">
        <a href="www.facebook.com">
          <FaFacebookF />
        </a>
        <a href="www.instagram.com">
          <FiInstagram />
        </a>
        <a href="www.twitter.com">
          <GrTwitter />
        </a>
      </div>
    </footer>
  );
};
