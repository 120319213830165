import React from "react";
import "./Experience.css";
import { VscDebugBreakpointData } from "react-icons/vsc";

export const Experience = () => {
  return (
    <section id="experience">
      <h5>My Work History</h5>
      <h2>Product Management and Auditing</h2>
      <h2>Ernst & Young LLP</h2>

      <div className="container experience__container">
        <div className="experience__product_management">
          <h3>Product Manager</h3>
          <h5>April 2022 - Current</h5>
          <div className="experience__content">
            <article className="experience__details">
              <VscDebugBreakpointData className="experience__details_icon" />
              <p>
                Lead the development of user interfaces for audit tools
                (software used for auditing)
              </p>
              <VscDebugBreakpointData className="experience__details_icon" />
              <p>
                Defines and maintains product roadmaps in Aha! to ensure the
                team stays on track with deliverables.
              </p>
              <VscDebugBreakpointData className="experience__details_icon" />
              <p>
                Work with Product Owners in developing User Stories for all
                functionalities, and prioritizing features in Aha
              </p>
            </article>
          </div>
        </div>
        <div className="experience__audit">
          <h3>Audit Senior Associate</h3>
          <h5>September 2019 - April 2022</h5>
          <div className="experience__content">
            <article className="experience__details">
              <VscDebugBreakpointData className="experience__details_icon" />
              <p>
                Assist in the design, planning, and execution of the audit
                procedures in accordance with the US Generally Accepted
                Accounting Principles (GAAP)
              </p>
              <VscDebugBreakpointData className="experience__details_icon" />
              <p>
                Tested the design and effectiveness of over 500 internal
                controls by performing walk-throughs of complex business process
              </p>
              <VscDebugBreakpointData className="experience__details_icon" />
              <p>
                Supervise staff and interns, detail review their work to ensure
                company policies are followed, and recommend revisions.
              </p>
              <VscDebugBreakpointData className="experience__details_icon" />
              <p>Prepare detailed reports on audit findings </p>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};
