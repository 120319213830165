import React from "react";
import { CTA } from "./CTA";
import ME from "../../assets/first1-removebg-preview.png";
import { HeaderSocials } from "./HeaderSocials";
import { CgScrollV } from "react-icons/cg";
import "../Header.css";

export const Header = () => {
  return (
    <header id="home">
      <div className="container header__container">
        <h5>Hello, I am</h5>
        <h1>Taku Feso</h1>
        <h5 className="text-light">
          An <strong>Auditor</strong>, who also{" "}
          <strong>Develop Software</strong>
        </h5>
        <CTA />
        <div className="pic__soc_scr">
          <HeaderSocials />

          <div className="me">
            <img src={ME} alt="" />
          </div>

          <a href="#contact" className="scroll__down">
            <CgScrollV />
          </a>
        </div>
      </div>
    </header>
  );
};
