import React from "react";
import IMG1 from "../../assets/typing2.jpg";
import IMG2 from "../../assets/quotes2.jpg";
import IMG3 from "../../assets/to_do_list.jpg";
import IMG4 from "../../assets/picture_in_picture.jpg";
import "./Portfolio.css";

const data = [
  {
    id: 1,
    image: IMG1,
    tittle: "Typing Speed Calculator",
    github: "https://github.com/captainfeso/Typing-Speed-Calculator",
    demo: "https://captainfeso.github.io/Typing-Speed-Calculator/",
  },
  {
    id: 2,
    image: IMG2,
    tittle: "Inspirational Quotes",
    github: "https://github.com/captainfeso/generate-quote",
    demo: "https://captainfeso.github.io/generate-quote/",
  },
  {
    id: 3,
    image: IMG3,
    tittle: "To Do List",
    github: "https://github.com/captainfeso/To-Do-List",
    demo: "https://captainfeso.github.io/To-Do-List/",
  },
  {
    id: 4,
    image: IMG4,
    tittle: "Picture-In-Picture",
    github: "https://github.com/captainfeso/picture-in-picture",
    demo: "https://captainfeso.github.io/picture-in-picture/",
  },
];
export const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>Some Of My Projects</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="porfolio__item_image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item__cta">
                <a
                  href={github}
                  className="btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Github
                </a>
                <a
                  href={demo}
                  className="btn btn-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Try It
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};
