import React from "react";
import "./About.css";
import ME from "../../assets/Feso-removebg-preview.png";
import { FaAward } from "react-icons/fa";
import { AiFillFolderOpen } from "react-icons/ai";
import { IoLogoGameControllerB } from "react-icons/io";
import { GiPopcorn } from "react-icons/gi";

export const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me_image">
            <img src={ME} alt="About Me" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h3>Experience</h3>
              <small>
                3 Years Of Financial Auditing / 2+ Years Building React Projects
              </small>
            </article>
            <article className="about__card">
              <AiFillFolderOpen className="about__icon" />
              <h3>Projects</h3>
              <small>
                20+ React Projects / Over 500 Leetcode Challenges / 4 Google
                Kick Starts
              </small>
            </article>
            <article className="about__card">
              <IoLogoGameControllerB className="about__icon" />
              <GiPopcorn className="about__icon" />
              <h3>Hobbies</h3>
              <small>
                <ul>
                  <li>Playing Soccer,</li>
                  <li>Reading books,</li>
                  <li>Watching Tv Shows</li>
                </ul>
              </small>
            </article>
          </div>
          <p>
            I am an auditor, and I develop software. I taught myself Computer
            Science while sheltering in place during Covid-19. My aspiration is
            to bring Auditing and Technology together; that is why I believe I
            would be a great IT Auditor. I believe my experiences in both
            Financial Auditing and Computer Science are essential for evaluating
            the design and effectiveness of internal controls.
          </p>
          <a href="contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};
