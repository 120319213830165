import React from "react";
import { BsLinkedin, BsFacebook } from "react-icons/bs";
import { FaGithubSquare } from "react-icons/fa";

export const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a
        href="https://www.linkedin.com/in/pascor-taku-feso/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BsLinkedin />
      </a>
      <a
        href="https://github.com/captainfeso"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaGithubSquare />
      </a>
      <a
        href="https://www.facebook.com/CaptainFeso"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BsFacebook />
      </a>
    </div>
  );
};
